
import './styles.scss';

function RewardsBar() {
    return (
        <div className="rewards-bar-container">
            <div className="rewards-bar">
                <span>Have you joined our loyalty program yet?</span>
                <a href="/skymint-rewards-information">SKYMINT Rewards</a>
                <a href="/gift-cards">Gift Cards</a>
            </div>
        </div>
    );
}

export default RewardsBar;
  