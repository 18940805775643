import './styles.scss';

function Logo() {
    return (
        <div className="logo-container">
            <span>SKYMINT</span>PREMIUM CANNABIS
        </div>
    );
}

export default Logo;
  