import Footer from "./components/footer/Footer";
import LoginForm from "./components/forms/LoginForm";
import Header from "./components/header/Header";
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore } from "firebase/firestore"; 
import { useState } from "react";
import RewardsForm from "./components/forms/RewardsForm";

// TODO: Replace the following with your app's Firebase project configuration
export const firebaseConfig = {
    apiKey: "AIzaSyBN2VrS5HxJDHQZrBJHf--7_OasQx77b6A",
    authDomain: "skymint-application.firebaseapp.com",
    projectId: "skymint-application",
    storageBucket: "skymint-application.appspot.com",
    messagingSenderId: "591337463826",
    appId: "1:591337463826:android:4c5f724e152bba0eeef8cb",
    measurementId: "G-BHJWP0ZYS9"
};

const app = initializeApp(firebaseConfig);


function Allowed() {
    const auth = getAuth();
    const [loggedIn, setLoggedin] = useState(false);
    
    onAuthStateChanged(auth, (user) => {
        if (user) {
            setLoggedin(true);
        }
    });

    return (
        <>
            <Header />

            {loggedIn !== false ? <RewardsForm setLoggedin={setLoggedin}/> : <LoginForm />}
            
            <Footer />
        </>
    );
}

export default Allowed;
