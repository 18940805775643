import Logo from '../logo/Logo';
import RewardsBar from '../rewards/RewardsBar';
import './styles.scss';

function Header() {
    return (
        <div className="header-container">
            <RewardsBar />
            <div className="skymint-container">
                <div className='header-navigation'>
                    <div className='left'>
                        <ul>
                            <li>
                                <a href="#">
                                    Who We Are
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    Knowledge
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    Strains
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className='logo'>
                        <Logo />
                    </div>
                    <div className='right'>

                        <ul>
                            <li>
                                <a href="#">
                                    Delivery
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    Deals
                                </a>
                            </li>
                            <li>
                                <a href="#" className='navigation-button'>
                                    Shop
                                </a>
                            </li>
                        </ul>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;
  