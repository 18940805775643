import { useCookies } from 'react-cookie';
import { useState } from "react";
import AgeGate from "./components/ageGate/AgeGate";
import Allowed from "./Allowed";
import NotAllowed from "./NotAllowed";

function App() {
  const [cookies, setCookie, removeCookie] = useCookies(['isover21']);
  const [isAllowed, setIsAllowed] = useState(cookies.isover21 ? cookies.isover21 : false);

  function handleClick(value)
  {
    setCookie('isover21', value)
    setIsAllowed(value);
  }

  if(isAllowed === 'denied')
  {
    return (
      <NotAllowed />
    )
  }

  if(isAllowed === false)
  {
    return (
      <AgeGate onClick={handleClick} cookies={cookies}/>
    )
  }

  if(isAllowed === 'true' || isAllowed === true)
  {
    return (
        <Allowed />
    );
  }

  
}

export default App;
