import './styles.scss';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useState } from 'react';

function LoginForm() {
    const auth = getAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword]= useState('');

    const handleEmail = (event) => {
        const value = event.target.value;
        setEmail(value);
    };

    const handlePassword = (event) => {
        const value = event.target.value;
        setPassword(value);
    };

    function submitForm()
    {

        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            console.log('success');
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);
        });
    }

    return (
        <div className="skymint-container">
            <div className="form-container">
                <form>
                    <div className="form-row">
                        <label>E-mail</label>
                        <input 
                            type="text" 
                            placeholder='E-mail' 
                            autoComplete='e-mail'
                            onChange={handleEmail}
                        />
                    </div>

                    <div className="form-row">
                        <label>Password</label>
                        <input 
                            type="password" 
                            placeholder='Password' 
                            autoComplete='current-password'
                            onChange={handlePassword}
                        />
                    </div>

                    <div className="form-row">
                        <div className='form-button' onClick={submitForm}>
                            Submit
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default LoginForm;