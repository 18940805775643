import './styles.scss';

function AgeGate({onClick}) {
    return (
        <div className="age-gate">
            <div className='skymint-container'>
                

                <div className='age-gate-form'>
                    <h4>Hey There</h4>
                    <h2>ARE YOU 21+?</h2>
                    
                    <div className='buttons'>
                        <div className='age-gate-button yes' onClick={() => onClick(true)}>Yes</div>
                        <div className='age-gate-button no' onClick={() => onClick('denied')}>No</div>
                    </div>
                    
                    <div className='disclaimer'>
                        By entering the site, you are agreeing to SKYMINT's Terms & Conditions.
                        If you do not agree, please leave the site immediately.
                    </div>

                    <div className='links'>
                        <a href="https://skymint.com/privacy-policy">Privacy Policy</a>
                        <a href="https://skymint.com/terms-and-conditions">Terms & Conditions</a>
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default AgeGate;
  