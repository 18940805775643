import './styles.scss';
import { getAuth, signOut } from "firebase/auth";
import { getFirestore, getDoc, doc } from "firebase/firestore"; 
import { useState } from 'react';

function RewardsForm({setLoggedin}) {
    const auth = getAuth();
    const db = getFirestore();
    const [extraUserData, setExtraUserData] = useState();
    const [rewardData, setRewardData] = useState();

    async function fetchUsersData(uid, setExtraUserData) {
        await getDoc(doc(db, "users",uid))
        .then((snap)=>{              
            setExtraUserData(snap.data());
        })
    }

    async function fetchRewardData(rewardId, setExtraUserData) {
        await getDoc(doc(db, "rewards",rewardId))
        .then((snap)=>{              
            setRewardData(snap.data());
        })
    }

    function handleSignOut()
    {
        signOut(auth);
        setLoggedin(false);
    }

    function resetForm()
    {
        setExtraUserData();
    }

    function confirmRedemption()
    {
        alert('Confirmed');
    }

    const showResult = (event) => {
        
        const search = event.target[0].value;
        const uid = search.split('|')[0];
        const rewardId = search.split('|')[1];
        console.log(uid, rewardId);

        fetchUsersData(uid, setExtraUserData);
        fetchRewardData(rewardId, setRewardData);
    }

    if(extraUserData !== undefined)
    {

        return (
            <div className="skymint-container rewards-form-container">
                <div className="form-container">
                    <h2>Customer Information</h2>
                    <div className="form-row customer-name">
                        Redeeming for {extraUserData.firstName} {extraUserData.lastName} 
                        <span>(UID: {extraUserData.uid})</span>
                    </div>
                    <div className="form-row">
                        Total Points Available: {extraUserData.rewardPoints}
                    </div>
                    <div className="form-row">
                        <div className='form-button' onClick={resetForm}>
                            Reset
                        </div>
                    </div>
                </div>

                <div className="form-container">
                    <h2>Reward Information</h2>
                    <div className="form-row reward-name">
                        <strong>{rewardData.name}</strong>
                        <span>Reward ID: {rewardData.id}</span>
                        <span>Tier: {rewardData.tier}</span>
                    </div>
                    <div className="form-row">
                        <strong>Point Cost</strong> {rewardData.cost}
                    </div>

                    <div className="form-row">
                       <strong>Description/Disclaimer</strong> {rewardData.description}
                    </div>
                    <div className="form-row">
                        <div className='form-button' onClick={confirmRedemption}>
                            Confirm Redemption?
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    return (
        <div className="skymint-container">
            
            <div className="form-container">
                <div className='form-button' onClick={handleSignOut}>Logout</div>
                <br />
                <form onSubmit={showResult}>
                    <div className="form-row">
                        <label>Scan the customer's QR Code</label>
                        <input 
                            id="userScan"
                            type="text" 
                        />
                    </div>
                </form>
            </div>
        </div>
    );
}

export default RewardsForm;