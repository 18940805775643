import Logo from '../logo/Logo';
import './styles.scss';

function Footer() {
    return (
        <div className="footer-container">
            
            <div className='footer-row'>
                <div className='footer-section left'>
                    <ul className='footer-navigation'>
                        <li>
                            <a href="#">Locations</a>
                        </li>

                        <li>
                            <a href="#">Careers</a>
                        </li>

                        <li>
                            <a href="#">Contact</a>
                        </li>

                        <li>
                            <a href="#">Investors</a>
                        </li>
                    </ul>
                </div>
                <div className='logo'>
                    <Logo />
                </div>
                <div className='footer-section right'>
                    <ul className='social'>
                        <li>
                            <a href="#">Facebook</a>
                        </li>

                        <li>
                            <a href="#">Instagram</a>
                        </li>

                        <li>
                            <a href="#">Youtube</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div className='footer-row'>
                <div className='footer-section left'>
                    Newsletter Form
                </div>
                <div className='footer-section right'>
                    Equality Icons
                </div>
            </div>

            <div className='footer-row'>
                <div className='footer-section full'>
                    <div className="copyright-text">Copyright ®2023 Skymint Premium Cannabis.</div>

                    <div className='footer-links'>
                        <ul>
                            <li>
                                <a href="#">Privacy Policy</a>
                            </li>

                            <li>
                                <a href="#">Terms & Conditions</a>
                            </li>

                            <li>
                                <a href="#">Loyalty Terms</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
  